import React from 'react'

import styles from './AlertList.module.scss'
import { LoadingDots } from 'components/shared/Loading'

export default ({ title, children, loading }) => (
  <div className={styles.container}>
    <div className={styles.header}>{title}</div>
    <div className={styles.spacer} />
    <div className={styles.body}>
      {children}
      <LoadingDots loading={loading} />
    </div>
  </div>
)
