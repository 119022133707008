import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import { Provider as MobXStoreProvider } from 'mobx-react'
import ErrorBoundary from 'react-error-boundary'

import BaseLayout from './Base'
import * as routes from 'constants/routes'
import { AuthService } from 'services'
import { searchStore } from '../SearchStore'
import PageCrash from 'components/PageCrash/PageCrash'

export default function BasePage(props) {
  const [loaded, setLoaded] = useState(false)

  const { location, onAuthCheck, ...layoutProps } = props

  useEffect(() => {
    let destroyFn
    // Keep track of user's login state
    AuthService.getCurrentUser((authUser, listener) => {
      if (
        typeof onAuthCheck === 'function' &&
        onAuthCheck(authUser) === false
      ) {
        const { pathname, search = '', hash = '' } = location
        const redirectURL = `${pathname}${search}${hash}`
        // redirect to sign in page
        return navigate(`${routes.SIGN_IN}?redirectURL=${redirectURL}`, {
          replace: true,
          state: {
            ...(props.location.state || {}),
            redirectURL,
          },
        })
      }
      // User is authorized
      destroyFn = listener
      setLoaded(true)
    }, true)
    return () => typeof destroyFn === 'function' && destroyFn()
  }, [])

  return (
    <ErrorBoundary
      onError={e => console.error(e)}
      FallbackComponent={PageCrash}
    >
      {loaded && <BaseLayout location={location} {...layoutProps} />}
    </ErrorBoundary>
  )
}
