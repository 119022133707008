import React, { Component } from 'react'
import { Link } from 'gatsby'

import styles from './UserBlurb.module.scss'
import Avatar from './Avatar'
import { getRoute } from 'utils'

export default ({
  user,
  showName,
  showMessage,
  linkProfile = true,
  ...rest
}) => {
  if (!user) {
    return <div />
  }
  return (
    <div className={styles.container}>
      <Avatar user={user} linkProfile={linkProfile} {...rest} />
      <div className={styles.subContainer}>
        {showName && (
          <Link to={getRoute('PROFILE', { id: user.id })}>
            <div className={styles.name}>{user.firstName}</div>
          </Link>
        )}
        {showMessage && (
          <Link to={`/message?recipient=${user.id}`}>
            <div className={styles.message}>Message</div>
          </Link>
        )}
      </div>
    </div>
  )
}
