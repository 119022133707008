import React from 'react'
import Link from 'gatsby-link'

import Layout from 'layouts/Page'
import SEO from 'components/shared/SEO'

const AboutPage = () => <div />

export default ({ location }) => (
  <Layout navbar="main" location={location}>
    <SEO title="About Borrowed" />
    <AboutPage />
  </Layout>
)
