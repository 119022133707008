import React from 'react'
import { Link } from 'gatsby'

import styles from './SignedOut.module.scss'
import ResponsiveDropdownList from 'components/shared/ResponsiveDropdownList'
import QuickLinks from 'components/QuickLinks'
import Messages from 'components/MessagePage/Messages'
import useMediaQuery from 'utils/hooks/useMediaQuery'
import { HamburgerIcon } from 'components/shared/Icons'
import Button from 'components/shared/Button'

export default function SignedOutPlate({ transparent, isScrolled }) {
  const isTablet = useMediaQuery(`(min-width: 900px)`)
  return (
    <ResponsiveDropdownList
      collapseAt={100}
      collapseLabel={3}
      className={[
        styles.container,
        transparent && !isScrolled && styles.transparent,
      ].join(' ')}
      style={{}}
      data={[
        {
          order: 3,
          show: isTablet,
          label: (
            <Link className={styles.navLink} to="/submit">
              List An Item
            </Link>
          ),
        },
        {
          order: 2,
          show: isTablet,
          label: (
            <Link className={styles.navLink} to="/for-business">
              For Businesses
            </Link>
          ),
        },
        {
          order: 2,
          show: isTablet,
          label: (
            <Link className={styles.navLink} to="/quote">
              Get Quotes
            </Link>
          ),
        },
        {
          order: 1,
          show: !isTablet,
          label: (
            <div className={styles.quickLinksContainer}>
              <HamburgerIcon className={styles.hamburgerBtn} />
            </div>
          ),
          item: <QuickLinks />,
        },
        {
          order: 0,
          show: isTablet,
          label: (
            <>
              <Link
                to={'/users/login'}
                className={[styles.actionButton, styles.bordered].join(' ')}
              >
                Log in
              </Link>
              <Link
                to={'/users/sign_up'}
                className={[styles.actionButton, styles.reverse].join(' ')}
              >
                Sign up
              </Link>
            </>
          ),
        },
      ]}
    />
  )
}
