import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import { Link } from 'gatsby'

import Searchbar from '../Searchbar'
import styles from './navbar.module.scss'
import Borrowed from '../shared/Brand/Borrowed'
import { AuthService } from 'services'
import ProfilePlate from './Profile'
import SignedOutPlate from './SignedOut'
import { NavbarUIState } from './NavbarConfig'
import useMediaQuery from 'utils/hooks/useMediaQuery'

export default observer(function Navbar(props) {
  // NavbarUIState state take precedence
  const augmentedProps = {
    ...props,
    ...(props.disableStore ? {} : NavbarUIState.state),
  }
  const {
    className,
    style = {},
    searchBarProps = {},
    mode = 'sticky',
    hideLeft = false,
    hideRight = false,
    showSearchBar = true,
    scrollEffects = true,
    overlay = false,
    transparent = false,
  } = augmentedProps

  const ref = useRef()

  const [isScrolled, setScrolled] = useState(false)
  const [didScrollUp, setDidScrollUp] = useState(false)
  const [isStuck, setIsStuck] = useState(false)
  const [offset, setOffset] = useState(null)

  const isPhone = useMediaQuery(`(max-width: 599px)`)

  useLayoutEffect(() => {
    if (!scrollEffects) {
      return
    }
    let lastPos = 0
    const scrollListener = () => {
      setScrolled(window.pageYOffset > 55)
      // uncomment the following two lines for an interesting effect
      // setDidScrollUp(window.pageYOffset > lastPos)
      // lastPos = window.pageYOffset
    }
    document.addEventListener('scroll', scrollListener)
    return () => document.removeEventListener('scroll', scrollListener)
  }, [scrollEffects])

  function rightComponent() {
    const { rightComponent = true } = augmentedProps
    if (rightComponent !== true) {
      return rightComponent
    }

    if (AuthService.auth.currentUser) {
      return <ProfilePlate transparent={transparent} isScrolled={isScrolled} />
    } else {
      return (
        <SignedOutPlate transparent={transparent} isScrolled={isScrolled} />
      )
    }
  }

  function leftComponent() {
    const { leftComponent = true } = augmentedProps
    if (leftComponent !== true) {
      return leftComponent
    }
    return (
      <>
        <Link className={styles.logo} to="/">
          <Borrowed height={isPhone ? 25 : 35} />
        </Link>
      </>
    )
  }
  return (
    <div
      ref={ref}
      style={style}
      className={[
        className,
        styles.wrapper,
        styles[mode],
        transparent ? styles.transparent : '',
        overlay ? styles.overlay : '',
        isStuck ? styles.stuck : '',
        isScrolled ? styles.scrolled : '',
        didScrollUp ? styles.scrolledUp : '',
      ].join(' ')}
    >
      <div
        className={[styles.container, styles[mode], isStuck && styles.stuck]
          .filter(Boolean)
          .join(' ')}
      >
        <div
          className={[styles.section, styles.left, hideLeft && styles.hidden]
            .filter(Boolean)
            .join(' ')}
        >
          {leftComponent()}
        </div>
        <div
          className={[
            styles.section,
            styles.center,
            !showSearchBar && styles.hidden,
          ]
            .filter(Boolean)
            .join(' ')}
        >
          {/*showSearchBar && <Searchbar {...searchBarProps} />*/}
        </div>
        <div
          className={[styles.section, styles.right, hideRight && styles.hidden]
            .filter(Boolean)
            .join(' ')}
        >
          {rightComponent()}
        </div>
      </div>
    </div>
  )
})
