import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import ModalWrapper, { ModalWidth } from './ModalWrapper'
import Button from 'components/shared/Button'

class ModalDialog extends React.Component {
  render() {
    const {
      show,
      heading,
      detail,
      onClose,
      primaryCta,
      secondaryCta,
    } = this.props

    return (
      <ModalWrapper
        show={show}
        onClose={onClose || (() => undefined)}
        width={ModalWidth.Narrow}
      >
        <InnerContent>
          <Heading>{heading}</Heading>
          {detail && (
            <div size="3" color="black60">
              {detail}
            </div>
          )}
        </InnerContent>

        <ButtonBox>
          <Button onClick={primaryCta.action}>{primaryCta.text}</Button>
        </ButtonBox>
      </ModalWrapper>
    )
  }
}

const ButtonBox = styled('div')`
  padding: 10px 20px;
`

const InnerContent = styled('div')`
  padding: 5px 20px;
  box-sizing: border-box;
`

const Heading = styled('h5')`
  margin-bottom: 5px;
`

ModalDialog.propTypes = {
  show: PropTypes.bool,
  heading: PropTypes.node,
  detail: PropTypes.node,
  primaryCta: PropTypes.shape({
    text: PropTypes.string,
    onClick: PropTypes.func,
  }),
  onClose: PropTypes.func,
}

export default ModalDialog
