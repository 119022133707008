import React, { useState, useEffect } from 'react'

import AlertList from 'components/AlertList'

/**
 * A component for displaying a list of messages
 * For future implementation, messages need to be
 * grouped by rental+user rather than solely user or rental.
 * This should help lenders differentiate message chains.
 * @param {*} props
 */

const MessageBlurb = ({ item }) => (
  <div className={styles.notificationContainer}>
    <div className={styles.wrapper}>
      <div className={styles.text}>{item.body}</div>
    </div>
  </div>
)

export default function Messages(props) {
  const { maxItems } = props
  const [messages, setMessages] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    // TODO: Subscribe to firestore notification updatees
    return () => null // TODO: Unsubscribe from notification updates
  })
  return (
    <AlertList title="Messages" loading={loading}>
      {messages.map((message, i) => {
        ;<MessageBlurb item={message} key={i} />
      })}
      {messages.length == 0 && !loading && (
        <div className="empty-box">No Messages Yet</div>
      )}
    </AlertList>
  )
}
