import FirebaseServiceBase from './FirebaseServiceBase'
import keys from 'constants/keys'
import firebase from 'firebase/app'

/**
 * Database Service. Previously named FirebaseService.
 *
 * The new name represents the purpose of this file in that
 * it exposes functions that control database interactions.
 *
 * Anything that interacts with the database, but does not have
 * a more specific service should be here.
 */

export default new class DatabaseService extends FirebaseServiceBase {
  doCreateUser = (id, fname, lname, phone, type, email) => {
    return this.firestore
      .collection('users')
      .doc(id)
      .set({
        firstName: fname,
        lastName: lname,
        type: type,
        phone: phone,
        email: email,
        avg_rating: 5,
        rating_count: 0,
        createdAt: firebase.firestore.Timestamp.now(),
        newsletterSubscribed: true,
      })
  }

  /**
   * Pings the user endpoint.
   * Can be used as a quick way to wake up the firebase functions
   * so that they'll be faster for an upcoming action.
   */
  ping = async () => {
    await this.http(`/users/${this.auth.currentUser.uid}/hello`)
  }

  addUserPhoto = photoUrl => {
    const userRef = this.firestore
      .collection('users')
      .doc(this.auth.currentUser.uid.toString())
    return userRef.update({
      picture: photoUrl,
    })
  }

  verifyEmail = () => {
    if (!this.auth.currentUser) {
      return
    }
    const userRef = this.firestore
      .collection('users')
      .doc(this.auth.currentUser.uid.toString())

    return userRef.update({
      emailVerified: true,
    })
  }

  getUsers = () => {
    return this.firestore
      .collection('users')
      .orderBy('createdAt', 'desc')
      .get()
  }

  /**
   * Returns the current user's doc ref
   * @returns {firebase.Firestore.DocumentReference}
   */
  get currentUser() {
    if (!this.auth.currentUser) {
      return null
    }
    return this.firestore.collection('users').doc(this.auth.currentUser.uid)
  }

  getCurrentUser = () => {
    if (!this.auth.currentUser) {
      return
    }
    return this.firestore
      .collection('users')
      .doc(this.auth.currentUser.uid.toString())
      .get()
  }

  addUserPaymentSource = async source => {
    if (this.currentUser === null) {
      return
    }
    await this.http(`/users/${this.auth.currentUser.uid}/payment/sources`, {
      method: 'POST',
      body: JSON.stringify({
        source,
      }),
    })
  }

  updateCurrentUser = data => {
    let updateData = {}
    const userRef = this.firestore
      .collection('users')
      .doc(this.auth.currentUser.uid.toString())
    if (data.firstName) {
      updateData.firstName = data.firstName
    }
    if (data.lastName) {
      updateData.lastName = data.lastName
    }
    if (data.picture) {
      updateData.picture = data.picture
    }
    if (data.description) {
      updateData.description = data.description
    }
    if (data.city) {
      updateData.city = data.city
    }
    if (data.phone) {
      updateData.phone = data.phone
    }
    return userRef.update({
      ...updateData,
    })
  }

  getUserById = id => {
    return this.firestore
      .collection('users')
      .doc(id.toString())
      .get()
  }

  updateSubscribe = ({ type, subscribe }) => {
    let updateVal = {}
    if (type == 'email') {
      updateVal = { newsletterSubscribed: subcribe }
    } else if (type == 'text') {
      updateVal = { textSubscribed: subcribe }
    }
    const userRef = this.firestore
      .collection('users')
      .doc(this.auth.currentUser.uid.toString())
    return userRef.update(updateVal)
  }

  // *** Items API ***

  getItemAvailability = async itemRef => {
    if (typeof itemRef == 'string') {
      itemRef = this.firestore.doc(`/fleet/${itemRef}`)
    }
    const items = await itemRef
      .collection('availability')
      .where('end_date', '>=', firebase.firestore.Timestamp.now())
      .get()
    if (items.empty) {
      return []
    }

    return await Promise.all(
      items.docs.map(async item => {
        const { start_date, end_date } = await item.data()
        return { start_date, end_date }
      })
    )
  }

  getFleetByOwner = id => {
    const userRef = this.firestore.collection('users').doc(id)
    return this.firestore
      .collection('fleet')
      .where('approved', '==', true)
      .where('user', '==', userRef)
      .get()
  }

  getCurrentUserItems = () => {
    const userRef = this.firestore
      .collection('users')
      .doc(this.auth.currentUser.uid.toString())
    return this.firestore
      .collection('fleet')
      .where('user', '==', userRef)
      .where('deleted', '==', false)
      .get()
  }

  /**
   * Create an item
   */
  createItem = item => {
    const userRef = this.firestore
      .collection('users')
      .doc(this.auth.currentUser.uid.toString())
    let price_format = Number(item.price_d)
    return userRef
      .get()
      .then(doc => {
        let user_data = doc.data()
        return this.firestore.collection('fleet').add({
          deleted: false,
          approved: false,
          description: item.description,
          pictures: item.pictures,
          name: item.name,
          price_d: price_format,
          type: item.type,
          subtype: item.type + ' > ' + item.subtype,
          street_address: item.street_address,
          _geoloc: {
            lat: item.lat,
            lng: item.lng,
          },
          zip: item.zip,
          city: item.city,
          state: item.state,
          user: userRef,
          user_name: user_data.firstName,
          user_img: user_data.picture,
          avg_rating: 5,
          rating_count: 0,
          createdAt: firebase.firestore.Timestamp.now(),
        })
      })
      .catch(function(error) {
        console.log('Error requesting item creation:', error)
      })
  }

  updateItem = data => {
    let updateData = {}
    const itemRef = this.firestore.collection('fleet').doc(data.id)
    if (data.name) {
      updateData.name = data.name
    }
    if (data.price_d) {
      updateData.price = {
        price: data.price_d * 100,
        formatted: {
          day: `$${data.price_d}`,
        },
      }
    }
    if (data.pictures) {
      updateData.pictures = data.pictures
    }
    if (data.type) {
      updateData.type = data.type
    }
    if (data.description) {
      updateData.description = data.description
    }
    if (data.zipcode) {
      updateData.zipcode = data.zipcode
    }
    return itemRef.update({
      ...updateData,
    })
  }

  deleteItem = id => {
    const itemRef = this.firestore.collection('fleet').doc(id)
    return itemRef.update({
      deleted: true,
    })
  }

  // *** Rentals API ***
  /**
   * Creates a rental for the currently logged in user
   *
   * @typedef RentalCreationData
   * @property {any} source Stripe source or token
   * @property {string} itemId Id of item to rent
   * @property {number} start_date Unix Timestamp of start date
   * @property {number} end_date Unix Timestamp of end date
   *
   * @typedef RentalCreationResponse
   * @property {boolean} created true if rental successfully created
   * @property {number} status HTTP status code
   * @property {{id:string}} data response data, including id of newly created rental
   *
   * @param {RentalCreationData} data
   * @returns {RentalCreationResponse}
   */
  createRental = async data => {
    const { itemId, start_date, end_date, source } = data

    const renterID = this.auth.currentUser.uid.toString()

    const response = await this.http('/rentals', {
      method: 'POST',
      body: JSON.stringify({
        source,
        renterID,
        itemID: itemId,
        start_date,
        end_date,
      }),
    })
    if (!response.ok) {
      throw new Error('Could not create rental')
    }
    const result = await response.json()
    return result
  }

  getRentalById = id => {
    return this.firestore
      .collection('rentals')
      .doc(id.toString())
      .get()
  }

  updateRentalById = (id, data = {}) => {
    return this.firestore.doc(`rentals/${id}`).update(data)
  }

  setRentalDecisionById = (id, decision) => {
    if (typeof decision !== 'boolean') {
      throw new TypeError(
        `Expected decision to explicitly be a boolean but got ${decision}`
      )
    }
    return this.updateRentalById(id, { approved: decision })
  }

  /**
   * @deprecated use updateRentalById instead
   */
  updateRental = data => {
    let updateData = {}
    const rentalRef = this.firestore.collection('rentals').doc(data.id)
    if (data.approved != null) {
      updateData.approved = data.approved
    }
    //return null
    return rentalRef.update({
      ...updateData,
    })
  }

  getCurrentUserRentalHistory = () => {
    let userRef = this.firestore
      .collection('users')
      .doc(this.auth.currentUser.uid.toString())

    return this.firestore
      .collection('rentals')
      .where('renter', '==', userRef)
      .orderBy('createdAt', 'desc')
      .get()
  }

  getCurrentUserLoanerHistory = () => {
    let userRef = this.firestore
      .collection('users')
      .doc(this.auth.currentUser.uid.toString())

    return this.firestore
      .collection('rentals')
      .where('lender', '==', userRef)
      .orderBy('createdAt', 'desc')
      .get()
  }

  // *** Message API ***

  getMessageById = id => {
    return this.firestore
      .collection('messages')
      .doc(id.toString())
      .get()
  }

  createMessage = data => {
    let { message, recipientID } = data
    let senderRef = this.firestore
      .collection('users')
      .doc(this.auth.currentUser.uid.toString())
    let sentToRef = this.firestore.collection('users').doc(recipientID)
    return this.firestore.collection('messages').add({
      from: senderRef,
      to: sentToRef,
      body: message,
      createdAt: firebase.firestore.Timestamp.now(),
    })
  }

  // *** Reviews API ***

  createReview = (data, reviewType, reviewerType) => {
    let { item_rating, item_review, user_review, user_rating, rental } = data
    let {
      id,
      lender,
      renter,
      lender_name,
      renter_name,
      lender_img,
      renter_img,
      item,
    } = rental
    let reviewer_id = this.auth.currentUser.uid.toString()
    let reviewerRef = this.firestore.collection('users').doc(reviewer_id)

    let body = ''
    let reviewer_img = ''
    let rating = ''
    let reviewer_name = ''
    let updateReviewRef = {}

    if (reviewType == 'item') {
      let item_id = item.id
      updateReviewRef = this.firestore
        .collection('fleet')
        .doc(item_id)
        .collection('reviews')
        .doc(reviewer_id)
      body = item_review
      rating = item_rating
    } else {
      let lender_id = lender.id
      updateReviewRef = this.firestore
        .collection('users')
        .doc(lender_id)
        .collection('reviews')
        .doc(reviewer_id)
      body = user_review
      rating = user_rating
    }
    if (reviewerType == 'renter') {
      reviewer_img = renter_img
      reviewer_name = renter_name
    } else {
      reviewer_img = lender_img
      reviewer_name = lender_name
    }

    return updateReviewRef.set(
      {
        rating: rating,
        body: body,
        reviewer_name: reviewer_name,
        reviewer_img: reviewer_img,
        reviewer: reviewerRef,
      },
      { merge: true }
    )
  }

  getReviewsByLoaner = id => {
    const userRef = this.firestore.collection('users').doc(id)
    return this.firestore
      .collection('reviews')
      .where('loaner', '==', userRef)
      .get()
  }

  getReviewsByItem = id => {
    const itemRef = this.firestore.collection('fleet').doc(id)
    return this.firestore
      .collection('reviews')
      .where('item', '==', itemRef)
      .get()
  }
}()
