import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import PropTypes from 'prop-types'

import FadeTransition from 'components/shared/Animations/FadeTransition'
import { CtaProps } from './ModalCta'
import { breakpoints } from 'utils/styles'

export const ModalWidth = {
  Narrow: '280px',
  Normal: '440px',
  Wide: '900px',
}

class ModalWrapper extends React.Component {
  state = {
    isAnimating: this.props.show || false,
    isShown: this.props.show || false,
    blurContainers: this.props.blurContainerSelector
      ? Array.from(document.querySelectorAll(this.props.blurContainerSelector))
      : [],
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        isAnimating: true,
        isShown: nextProps.show,
      })
    }
  }

  componentWillUnmount() {
    this.removeBlurToContainers()
  }

  close = () => {
    this.props.onClose()
    this.removeBlurToContainers()
  }

  addBlurToContainers = () => {
    for (const container of this.state.blurContainers) {
      container.classList.add('blurred')
    }
  }

  removeBlurToContainers = () => {
    for (const container of this.state.blurContainers) {
      container.classList.remove('blurred')
    }
  }

  render() {
    const { children, width, fullscreenResponsiveModal, image } = this.props
    const { isShown, isAnimating } = this.state

    if (isShown) {
      this.addBlurToContainers()
    } else {
      this.removeBlurToContainers()
    }

    return (
      <Wrapper isShown={isShown || isAnimating}>
        {isShown && <ModalOverlay onClick={this.close} />}
        <FadeTransition
          in={isShown}
          mountOnEnter
          onExited={() => {
            this.setState({ isAnimating: false })
          }}
          unmountOnExit
          timeout={{ enter: 10, exit: 200 }}
        >
          <ModalContainer
            fullscreenResponsiveModal={fullscreenResponsiveModal}
            width={width}
            image={image}
          >
            <ModalInner fullscreenResponsiveModal={fullscreenResponsiveModal}>
              {children}
            </ModalInner>
          </ModalContainer>
        </FadeTransition>
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  ${props =>
    props.isShown &&
    `position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999
  `};
`

const slideUp = keyframes`
  from {
    transform: translate(-50%, -40%);
    opacity: 0;
    /* stylelint-disable-next-line */
  },
  to {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
`

export const ModalOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(200, 200, 200, 0.5);
`

export const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  width: 100%;
  @media (min-width: ${breakpoints.landScapeUp}) {
    width: ${props => {
      if (props.image) {
        return ModalWidth.Wide
      } else {
        return props.width ? props.width : ModalWidth.Normal
      }
    }};
  }
  height: min-content;
  border-radius: 5px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  animation: ${slideUp} 250ms linear;
  @media (max-width: ${breakpoints.phoneOnly}) {
    ${props =>
      props.fullscreenResponsiveModal
        ? `width: 100%;
        border-radius: 0
        `
        : `width: calc(100vw - 20px);
          border-radius: 2px;
        `}
  }
`
// overflow-y: auto;
const ModalInner = styled.div`
  max-height: calc(100vh - 80px);
  @media (max-width: ${breakpoints.phoneOnly}) {
    ${props =>
      props.fullscreenResponsiveModal &&
      `max-height: 100vh;
       height: 100vh
      `}
  }
`

ModalWrapper.defaultProps = {
  show: false,
  fullscreenResponsiveModal: true,
  blurContainerSelector: '',
}

ModalWrapper.propTypes = {
  blurContainerSelector: PropTypes.string,
  cta: PropTypes.shape({
    isFixed: PropTypes.bool,
    text: PropTypes.string,
    onClick: PropTypes.func,
  }),
  onClose: PropTypes.func,
  width: PropTypes.string,
  fullscreenResponsiveModal: PropTypes.bool,
  image: PropTypes.string,
  show: PropTypes.bool,
}

export default ModalWrapper
