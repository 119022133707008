import React from 'react'
import { observable, action, computed, toJS, autorun, reaction } from 'mobx'
import { observer, inject } from 'mobx-react'
import { AuthService, DatabaseService } from 'services'

/**
 * This class is intended to house reactionable properties
 * of the current user in one place. Ultimately this is
 * useful for cutting down on database reads since multiple
 * components requiring the same information do not have to
 * retrieve it multiple times
 */
export default new class UserStateStore {
  constructor() {
    AuthService.getCurrentUser(user => {
      if (!user) {
        return
      }
      if (typeof this.listener === 'function') {
        this.listener()
      }
      this.listener = DatabaseService.currentUser.onSnapshot(snap => {
        if (!snap.exists) {
          return this.setUser(null)
        }
        this.setUser(snap)
      })
    }, true)
  }

  _user = observable.box(null)

  @computed get user() {
    return toJS(this._user.get())
  }

  @action setUser(snap) {
    const data = { id: snap.id, ...snap.data() }
    this._user.set(data)
  }
}()
