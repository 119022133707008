import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import Button from 'components/shared/Button'
import { breakpoints } from 'utils/styles'

const ModalCta = props => {
  const {
    cta: { isFixed, onClick, text },
    hasImage,
    onClose,
  } = props

  return (
    <Cta isFixed={isFixed} hasImage={hasImage}>
      <ButtonBox>
        <Button onClick={onClick || onClose}>{text}</Button>
      </ButtonBox>
    </Cta>
  )
}

const Cta = styled('div')`
  padding: $10px 0 $15px 0;
  button {
    margin: 0;
    width: 100%;
  }
  ${props =>
    props.isFixed &&
    `position: absolute;
    bottom: 0;
    right: 20px;
    left: ${props.hasImage ? 'calc(50% + 40px)' : '20px'};
    background: white;
    border-top: 1px solid #222;
  `};
  @media (max-width: ${breakpoints.phoneOnly}) {
    ${props => props.isFixed && `right: 10px;left: 10px`}
  }
`

const ButtonBox = styled('div')`
  padding: 25px 0 20px;
`

ModalCta.propTypes = {
  isFixed: PropTypes.bool,
  text: PropTypes.string,
  onClick: PropTypes.func,
}

export default ModalCta
