import React, { Component } from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

import { colors } from '../../../utils/styles'

const BounceAnimation = keyframes`
 from, 20%, 53%, 80%, to {
    transform: translate3d(0,0,0);
  }
  40%, 43% {
    transform: translate3d(0, -20px, 0);
  }
  70% {
    transform: translate3d(0, -8px, 0);
  }
  90% {
    transform: translate3d(0,-3px,0);
  }
`

const DotWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 30px 20px;
  justify-content: center;
  opacity: 0;
  animation: fadein 0.2s forwards;
  animation-delay: 0.6s;
`

const Dot = styled.div`
  background-color: #444;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  animation: ${BounceAnimation} 1s ease infinite;
  animation-delay: ${props => props.delay};
`

export default ({ loading, ...rest }) =>
  loading ? (
    <DotWrapper {...rest}>
      <Dot delay="0s" />
      <Dot delay=".1s" />
      <Dot delay=".2s" />
    </DotWrapper>
  ) : (
    <div />
  )
