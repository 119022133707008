import React from 'react'
import { Link } from 'gatsby'

import styles from './QuickLinks.module.scss'
import { AuthService } from 'services'
import { HamburgerIcon } from 'components/shared/Icons'

function QuickLink(props) {
  const { icon, children, ...rest } = props
  return (
    <Link className={styles.quickLink} {...rest}>
      {icon}
      {children}
    </Link>
  )
}

export default function QuickLinks(props) {
  const { user } = props

  return (
    <div className={styles.container}>
      <span className={styles.quickInfo} />
      {user ? (
        <div>
          <div className={styles.quickLinksWrapper}>
            <QuickLink to="/dashboard">Profile</QuickLink>
            <QuickLink to="/submit">List an item</QuickLink>
            <QuickLink to="/dashboard/items">My Inventory</QuickLink>
            <QuickLink to="/dashboard/rentals">Rental History</QuickLink>
            <QuickLink to="/dashboard/settings">Settings</QuickLink>
          </div>
          <div className={styles.signOutButton}>
            <QuickLink to="/" onClick={AuthService.doSignOut}>
              <div className={styles.container}>
                <div className={styles.title}>Sign Out</div>
                <div className={styles.email}>{user.email}</div>
              </div>
            </QuickLink>
          </div>
        </div>
      ) : (
        <div>
          <div className={styles.quickLinksWrapper}>
            <QuickLink to="/submit">List an Item</QuickLink>
            <QuickLink to="/for-business">For Business</QuickLink>
            <QuickLink to="/quote">Get Quotes</QuickLink>
          </div>
          <div className={styles.signOutButton}>
            <div className={styles.container}>
              <QuickLink to={'/users/login'}>Log in</QuickLink>
              <QuickLink to={'/users/sign_up'}>Sign up</QuickLink>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
