import React, { useState, useEffect } from 'react'

import styles from './Notifications.module.scss'
import AlertList from 'components/AlertList'

export const NotificationBlurb = ({ item }) => (
  <div className={styles.notificationContainer}>
    <div className={styles.wrapper}>
      <div className={styles.text}>{item.body}</div>
    </div>
  </div>
)

export default function Notifications(props) {
  const { maxItems } = props
  const [notifications, setNotifications] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    // TODO: Subscribe to firestore notification updatees
    return () => null // TODO: Unsubscribe from notification updates
  })
  return (
    <AlertList title="Notifications" loading={loading}>
      {notifications.map((notification, i) => {
        ;<NotificationBlurb item={notification} key={i} />
      })}
      {notifications.length == 0 && !loading && (
        <div className="empty-box">No Notifications Yet</div>
      )}
    </AlertList>
  )
}
