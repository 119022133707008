import React, { useState, useEffect } from 'react'
import { Provider as MobXStoreProvider } from 'mobx-react'
import styled from '@emotion/styled'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import 'scss/mellow.scss'
import { searchStore } from '../SearchStore'
import Navbar from 'components/Navbar'
import Footer from 'components/Footer'
import { AuthService, DatabaseService } from 'services'
import { breakpoints } from 'utils/styles'
import VerifyPasswordModal from 'components/VerifyPasswordModal/VerifyPasswordModal'
import useMediaQuery from 'utils/hooks/useMediaQuery'

const ViewPort = styled(`div`)`
  width: 100vw;
  max-width: 100vw;
  position: relative;
`

export default function Layout(props) {
  const {
    children,
    showBeacon = false,
    displayFooter = true,
    navbarProps = {},
    viewportProps = {},
    ...childProps
  } = props

  const isDesktopViewport = useMediaQuery(
    `(min-width: ${breakpoints.desktop}px)`
  )

  useEffect(() => {
    if (!showBeacon || !isDesktopViewport) {
      return
    }
    Beacon('init', '3b5c120d-7398-48fd-aa0a-467cf3acb48f')
    return () => Beacon('destroy')
  }, [showBeacon, isDesktopViewport])

  return (
    <MobXStoreProvider searchStore={searchStore}>
      <ViewPort {...viewportProps}>
        <VerifyPasswordModal />
        <Navbar location={props.location} {...navbarProps} />
        <div className="page-body-container">
          {React.Children.map(children, child =>
            React.cloneElement(child, {
              isDesktopViewport,
              // These are for backwards compatability, but they're
              // actually deprecated
              auth: AuthService,
              database: DatabaseService,
              currentUser: AuthService.currentUser,
              // location,
              ...childProps,
            })
          )}
        </div>
        <Footer hideFooter={!displayFooter} />
      </ViewPort>
    </MobXStoreProvider>
  )
}
