import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import * as routes from 'constants/routes'

import styles from './Footer.module.scss'

export default ({ hideFooter }) => (
  <>
    {!hideFooter && (
      <footer className={styles.container}>
        <div className={styles.content}>
          <div className={styles.inner}>
            <div className={styles.section}>
              <div className={styles.header}>Company</div>
              <Link to="/blog/" className={styles.footerLink}>
                Blog
              </Link>
              <Link to="/contact/" className={styles.footerLink}>
                Contact
              </Link>
              <Link to="/feedback/" className={styles.footerLink}>
                Feedback
              </Link>
              <Link to="/legal/terms" className={styles.footerLink}>
                Terms
              </Link>
              <Link to="/legal/privacy" className={styles.footerLink}>
                Privacy
              </Link>
            </div>
            <div className={styles.section}>
              <div className={styles.header}>Rentals</div>
              <Link to="/search/" className={styles.footerLink}>
                Browse items
              </Link>
              <Link to="/quote/" className={styles.footerLink}>
                Get a quote
              </Link>
              <Link to="/for-business/" className={styles.footerLink}>
                For Businesses
              </Link>
              <a
                href={routes.HELP}
                target="_blank"
                className={styles.footerLink}
              >
                Help
              </a>
            </div>
            <div className={styles.section}>
              <div className={styles.header}>Account</div>
              <Link to="/users/login" className={styles.footerLink}>
                Sign in
              </Link>
              <Link to="/users/sign_up" className={styles.footerLink}>
                Create account
              </Link>
              <Link to="/dashboard/items" className={styles.footerLink}>
                Inventory
              </Link>
              <Link to="/dashboard/rentals" className={styles.footerLink}>
                Rentals
              </Link>
              <Link to="/submit/" className={styles.footerLink}>
                List an item
              </Link>
            </div>
            <div className={styles.section}>
              <div className={styles.header}>Social</div>
              <a
                href={`https://facebook.com/${routes.FACEBOOK}`}
                target="_blank"
                className={styles.footerLink}
              >
                Facebook
              </a>
              <a
                href={`https://twitter.com/${routes.TWITTER}`}
                target="_blank"
                className={styles.footerLink}
              >
                Twitter
              </a>
              <a
                href={`https://instagram.com/${routes.INSTAGRAM}`}
                target="_blank"
                className={styles.footerLink}
              >
                Instagram
              </a>
            </div>
          </div>
        </div>
      </footer>
    )}
  </>
)
