import React from 'react'

import styles from './Profile.module.scss'
import ResponsiveDropdownList from 'components/shared/ResponsiveDropdownList'
import NotificationsMenu from 'components/Notifications'
import QuickLinks from 'components/QuickLinks'
import Messages from 'components/MessagePage/Messages'
import UserStateStore from 'state/UserStateStore'
import { AuthService } from 'services'
import useMediaQuery from 'utils/hooks/useMediaQuery'
import UserBlurb from 'components/Users/UserBlurb'
import { BellIcon, MailIcon, ChevronDown } from 'components/shared/Icons'

export default function ProfilePlate({ transparent, isScrolled }) {
  const authUser = AuthService.currentUser
  const isTablet = useMediaQuery(`(min-width: 900px)`)
  return (
    <ResponsiveDropdownList
      collapseAt={100}
      collapseLabel={2}
      className={[
        styles.container,
        transparent && !isScrolled && styles.transparent,
      ].join(' ')}
      data={[
        {
          order: 2,
          show: isTablet,
          label: (
            <div className={styles.navLink}>
              <BellIcon />
            </div>
          ),
          item: <NotificationsMenu />,
        },
        {
          order: 1,
          show: isTablet,
          label: (
            <div className={styles.navLink}>
              <MailIcon />
            </div>
          ),
          item: <Messages />,
        },
        {
          order: 0,
          label: (
            <div className={styles.quickLinksContainer}>
              <UserBlurb
                size="40px"
                user={{ picture: authUser.photoURL, firstName: authUser.name }}
                showName={false}
                linkProfile={false}
              />
              <ChevronDown />
            </div>
          ),
          item: <QuickLinks user={authUser} />,
        },
      ]}
    />
  )
}
