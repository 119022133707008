/**
 * Quick and dirty public keys implementation
 * that varies based on environment type
 */
const LOCAL_API_PORT = process.env.GATSBY_LOCAL_API_PORT
const CONFIG_TYPE = process.env.GATSBY_CONFIG_TYPE
const NODE_ENV = process.env.NODE_ENV

if (CONFIG_TYPE === 'production') {
  console.warn(
    'You are using the production config while in development mode.',
    'Be careful as this means any actions taken will potentially affect real users'
  )
}

const DEV_KEYS = {
  NODE_ENV,
  ALGOLIA: {
    APP_ID: 'EDMI1IDNFD',
    API_KEY: 'bff6f4b971657421f7b57691b443e603',
    INDEX: 'test_ITEMS',
  },
  STRIPE: {
    CONNECT_ID: 'ca_DVZzO3Ze1IYgmjs0vTYNsSeu195WSCv8',
    PUBLISHABLE_KEY: 'pk_test_h07oSpnJPTetxDoHFd8FodCT',
  },
  REST_API: {
    BASE_URL: LOCAL_API_PORT
      ? `http://localhost:${LOCAL_API_PORT}/mellow-development/us-central1/api`
      : `https://us-central1-mellow-development.cloudfunctions.net/api`,
  },
  FIREBASE: {
    apiKey: 'AIzaSyCs3W0iALcGHSD9ULPnmPljjvBEuP-DNJs',
    authDomain: 'mellow-development.firebaseapp.com',
    databaseURL: 'https://mellow-development.firebaseio.com',
    projectId: 'mellow-development',
    storageBucket: 'mellow-development.appspot.com',
    messagingSenderId: '974196497269',
  },
  TWILIO: {
    API_KEY: 'c6Bd9pgWSkBZtDGkdEHDvTkCV3d7JrD4',
  },
  UPLOADCARE: {
    API_KEY: 'a3ac466dc31e30e1930c',
    CDN: '',
  },
  GOOGLE_MAPS: {
    API_KEY: 'AIzaSyA_0G3ZKX89wmcQdG84SbwYrh0bMwotH7U',
  },
}

const PROD_KEYS = {
  ...DEV_KEYS,
  ALGOLIA: {
    APP_ID: 'EDMI1IDNFD',
    API_KEY: 'bff6f4b971657421f7b57691b443e603',
    INDEX: 'prod_ITEMS',
  },
  STRIPE: {
    CONNECT_ID: 'ca_DVZzUm3yhLOazNOPZtjnIrYfOnG5rA7e',
    PUBLISHABLE_KEY: 'pk_live_K8Kqx1kbwiNGUz24UhGgVftn',
  },
  REST_API: {
    BASE_URL: `https://us-central1-yellow-3952c.cloudfunctions.net/api`,
  },
  FIREBASE: {
    apiKey: 'AIzaSyBJSS077jZTtISCDi2T6S92NDogujsnVXI',
    authDomain: 'yellow-3952c.firebaseapp.com',
    databaseURL: 'https://yellow-3952c.firebaseio.com',
    projectId: 'yellow-3952c',
    storageBucket: 'yellow-3952c.appspot.com',
    messagingSenderId: '559709665390',
  },
  UPLOADCARE: {
    API_KEY: 'a3ac466dc31e30e1930c',
    CDN: '',
  },
  GOOGLE_MAPS: {
    API_KEY: 'AIzaSyA_0G3ZKX89wmcQdG84SbwYrh0bMwotH7U',
  },
}

const keys =
  NODE_ENV === 'production' || CONFIG_TYPE === 'production'
    ? PROD_KEYS
    : DEV_KEYS

if (NODE_ENV !== 'production') {
  console.groupCollapsed('Config Keys')
  console.log(keys)
  console.groupEnd()
}

export default keys
