import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

import styles from './UserBlurb.module.scss'
import { getRoute } from 'utils'
import { colors } from 'utils/styles'

export const ImgContainer = styled(`div`)`
  color: ${colors.themeAccent};
  background-color: ${colors.gray100};
  object-fit: cover;
  border-radius: 50%;
  width: ${props => (props.size ? props.size : '50px')};
  height: ${props => (props.size ? props.size : '50px')};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
`

const Avatar = ({ size, user }) => {
  if (!user) {
    return <div />
  }
  return (
    <ImgContainer size={size}>
      {user.picture ? (
        <img className={styles.avatar} src={user.picture} />
      ) : (
        <div className={styles.letters}>
          {user.firstName && user.firstName.charAt(0)}
          {user.lastName && user.lastName.charAt(0)}
        </div>
      )}
    </ImgContainer>
  )
}

export default ({ user, linkProfile, ...rest }) => {
  if (!user) {
    return <div />
  }
  if (linkProfile) {
    return (
      <Link to={getRoute('PROFILE', { id: user.id })}>
        <Avatar user={user} {...rest} />
      </Link>
    )
  }
  return <Avatar user={user} {...rest} />
}
