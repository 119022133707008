import React from 'react'

import ErrorModal from 'components/shared/Modal/ModalError'

export default class PageCrash extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isModalOpen: true }
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  openModal() {
    this.setState({ isModalOpen: true })
  }

  closeModal() {
    window.location.reload()
    this.setState({ isModalOpen: false })
  }

  render() {
    return (
      <ErrorModal onClose={this.closeModal} show={this.state.isModalOpen} />
    )
  }
}
