import React from 'react'
import { observable, action, computed, toJS, autorun, reaction } from 'mobx'
import { observer, inject } from 'mobx-react'
import { Configure, connectConfigure } from 'react-instantsearch-dom'
import qs from 'qs'

/**
 * A pretty simple mobx store that controls the search state
 *
 * This method of controlling the search state is necessary because
 * some components that control the search (such as thee navbar)
 * will have to exist outside of the InstantSearch Component.
 *
 * Another method of resolving this issue would have been to wrap the
 * entire page inside the InstantSearch component, but I'm not a fan
 * Algolia's connector interfaces anyway. Doing it this way does sort of require
 * a little more knowledge about Algolia's search parameters and a little
 * more manual handling, but it also completely centralizes search handling,
 * which makes it a little easier to work with overall.
 *
 * For specifics about the parameters.
 * @see https://www.algolia.com/doc/api-reference/search-api-parameters/
 */
export const searchStore = new class SearchStore {
  defaultState = {
    aroundLatLng: '38.894377,-77.040798',
    aroundRadius: Math.round(50 * 1609.34),
    page: 0,
    hitsPerPage: 24,
    query: '',
    facets: ['type'],
  }

  @observable state = {
    ...this.defaultState,
  }

  onChange(cb, delay = 0) {
    return reaction(() => this.searchState, () => cb(this.searchState, this), {
      delay,
    })
  }

  _internals = {}

  /**
   * Generic setter for the search state
   * Prefer to use the more specific setters below.
   * This method is useful for testing purposes but
   * shouldn't really be used.
   */
  @action setSearchState(state = {}) {
    let newState = state
    if (typeof state === 'function') {
      newState = state(this.state)
    }
    if (!newState) {
      return
    }
    for (let key in newState) {
      this.state[key] = newState[key]
    }
  }

  @action setPage(p) {
    this.state.page = p
  }

  @action setQuery(q) {
    this.state.query = q
  }
  /**
   *
   * @param {*} lat
   * @param {*} lng
   * @param {*} radius
   * @see
   */
  @action setLocation(lat = 0, lng = 0) {
    this.state.aroundLatLng = [lat, lng].join(',')
  }

  @action setRadius(radius = 50) {
    this.state.aroundRadius = Math.round(radius * 1609.34)
  }

  /**
   * Sets the facet filter
   * TODO: Should make this a little more complex
   * @see https://www.algolia.com/doc/api-reference/api-parameters/facetFilters/
   */
  @action setCategory(...category) {
    this.reset()
    // Clear previous filters
    if (!category.length) {
      this.state.facetFilters = []
      return
    }
    this.setPage(0)
    this.state.facetFilters = [category]
  }

  @action setFeatured() {
    this.reset()
    this.setPage(0)
    this.state.facetFilters = [`featured:true`]
  }
  /**
   * Naive facet check algorithm
   * @param {*} filter
   */
  hasCategory(category) {
    if (!this.state.facetFilters) {
      return false
    }
    return this.state.facetFilters.some(arr => arr.includes(category))
  }
  /**
   * Sets the state given a query string
   * @param {*} string
   */
  @action setStateFromQueryString(queryString) {
    try {
      const obj = qs.parse(queryString)
      // special include key that deletes itself
      for (let key in obj) {
        if (/^include$/.test(key)) {
          delete obj[key]
        }
        if (Number(obj[key])) {
          obj[key] = Number(obj[key])
        }
      }

      this.setSearchState({ ...this.defaultState, ...obj })
    } catch (err) {
      console.warn(err)
    }
  }

  /**
   * Reset to intiial search state
   */
  @action reset() {
    const values = { ...this.searchState, ...this.defaultState }
    for (let key in values) {
      // Remove values not part of initial state
      if (typeof this.defaultState[key] === 'undefined') {
        delete this.state[key]
      } else {
        this.state[key] = this.defaultState[key]
      }
    }
  }

  /**
   * Gets the query string from the search state
   */
  @computed get queryString() {
    const url = qs.stringify(this.searchState)
    return `?${url}`
  }

  @computed get searchState() {
    return toJS(this.state)
  }
}()

/**
 * This component is intended to be inserted wherever
 * InstantSearch is placed. It essentially watches the
 * searchStoree and then re-renders the Configure component
 * which triggers an algolia search.
 */
@inject('searchStore')
@observer
export class SearchController extends React.Component {
  componentDidMount() {
    const {
      searchStore,
      onSearchState,
      delay = 1000 * 0.4, // Doherty threshold
    } = this.props
    if (typeof onSearchState === 'function') {
      this.dispose = searchStore.onChange(onSearchState, delay)
    }
  }

  componentWillUnmount() {
    if (typeof this.dispose === 'function') {
      this.dispose()
    }
  }

  render() {
    // Dereferencee props that shouldn't be attached to Configure component
    const { searchStore, onSearchState } = this.props
    return <Configure {...searchStore.searchState} />
  }
}
