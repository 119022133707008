export const BASE = 'https://www.borrowed.me'
export const BLOG = 'https://www.borrowed.me/blog'
export const HELP = 'https://www.help.borrowed.me'
export const FACEBOOK = 'borrowedme'
export const TWITTER = '@borrowedme'
export const INSTAGRAM = 'borrowedme'
export const EMAIL = 'team@borrowed.me'
export const HOME = '/'
export const SEARCH = '/search'
export const SIGN_UP = '/users/sign_up'
export const SIGN_IN = '/users/login'
export const PASSWORD_FORGET = '/pw-forget'
export const ACCOUNT = '/dashboard'
export const EDIT_ITEM = '/dashboard/items?edit={{id}}'
export const PROFILE = '/profile?u={{id}}'
export const ITEM = '/item/{{id}}'
export const RENTAL_CONFIRMATION = '/rental-confirmation?id={{id}}'
