import React from 'react'
import PropTypes from 'prop-types'

import ModalDialog from './ModalDialog'
import * as routes from 'constants/routes'

class ErrorModal extends React.Component {
  close = () => {
    this.props.onClose && this.props.onClose()
  }

  render() {
    const {
      show,
      onClose,
      headerText,
      detailText,
      contactEmail,
      closeText,
      ctaAction,
    } = this.props
    const emailAddress = contactEmail ? contactEmail : routes.EMAIL

    return (
      <ModalDialog
        show={show}
        onClose={onClose}
        heading={headerText}
        detail={
          detailText || (
            <>
              Something went wrong. Please try again or contact{' '}
              <a href={`mailto:${emailAddress}`}>{emailAddress}</a>.
            </>
          )
        }
        primaryCta={{
          action: ctaAction || onClose,
          text: closeText,
        }}
      />
    )
  }
}

ErrorModal.defaultProps = {
  headerText: 'An error occurred',
  closeText: 'Continue',
}

ErrorModal.propTypes = {
  show: PropTypes.bool,
  headerText: PropTypes.string,
  detailText: PropTypes.string,
  contactEmail: PropTypes.string,
  closeText: PropTypes.string,
  onClose: PropTypes.func,
  ctaAction: PropTypes.func,
}

export default ErrorModal
